@if (controlInput?.invalid && controlInput?.touched) {
  @if (controlInput?.errors?.['required']) {
    <sub class="validation-error" data-testid="validation-empty">
      This field cannot be empty
    </sub>
  }
  @if (controlInput?.errors?.['minlength']) {
    <sub class="validation-error" data-testid="validation-must-contains-min">
      The field must contain at least {{ controlInput?.errors?.['minlength']?.['requiredLength'] }} characters
    </sub>
  }
  @if (controlInput?.errors?.['maxlength']) {
    <sub class="validation-error" data-testid="validation-must-contains-max">
      The field must contain at most {{ controlInput?.errors?.['maxlength']?.['requiredLength'] }} characters
    </sub>
  }
  @if (controlInput?.errors?.['email']) {
    <sub class="validation-error" data-testid="validation-invalid-email">
      Please enter a valid email address
    </sub>
  }
  @if (controlInput?.errors?.['pattern']) {
    <sub class="validation-error" data-testid="validation-invalid-pattern">
      {{ patternMessage }}
    </sub>
  }
  @if (controlInput?.errors?.['matching']) {
    <sub class="validation-error" data-testid="validation-invalid-matching">
      {{ matchingMessage }}
    </sub>
  }
  @if (controlInput?.errors?.['invalidInterval']) {
    <sub class="validation-error" data-testid="validation-invalid-invalid-interval">
      The end date must be greater than the start date
    </sub>
  }

  @if (controlInput?.errors?.['appointmentBigInterval']) {
    <sub class="validation-error" data-testid="validation-appointment-big-interval">
      An appointment cannot be longer than 1 hour
    </sub>
  }

  @if (controlInput?.errors?.['appointmentInPast']) {
    <sub class="validation-error" data-testid="validation-appointment-in-past">
      The appointment cannot be in the past
    </sub>
  }

}
