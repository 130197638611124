<div class="form-label" [ngClass]="styleClass">
  <div class="flex align-items-center w-full" [class.has-icon]="iconClass">
    @if (iconClass) {
      <div class="left-icon">
        <i [ngClass]="iconClass"></i>
      </div>
    }
    <div class="w-full">
      <span class="p-float-label" [attr.data-testid]="'field-' + label?.toLowerCase()">
        <ng-content></ng-content>
        <label [attr.data-testid]="'label-' + label?.toLowerCase()" [for]="htmlFor">{{ label }}</label>
      </span>
    </div>
  </div>
  @if (controlInput) {
    <app-validation [attr.data-testid]="'validation-message-' + label?.toLowerCase()"
                    [controlInput]="controlInput"></app-validation>
  }
</div>
